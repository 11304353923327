.th-partner {
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: normal;
  color: #521e86;
  border-bottom: 4px solid #ffc700;
}

.th-partner-logo {
  width: 75%;
}

@media only screen and (max-width: 768px) {
  .th-partner-logo {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .th-partner {
    font-size: 32px;
    margin-bottom: 5px !important;
  }
}

@media only screen and (min-width: 580px) and (max-width: 768px) {
  .th-partner {
    font-size: 22px;
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 580px) {
  .th-partner {
    font-size: 18px;
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 320px) {
  .th-partner {
    font-size: 16px;
    margin-bottom: 5px !important;
  }
}

.line {
  width: 9rem;
  height: 1.75px;
  background: #ffc700;
  margin-bottom: 40px;
}


.sponsor-mob{
    width: 100%;
    padding: 47px 29px;
}

.sponsor-mob h1{
    text-align: center;
    padding-bottom:20px;
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
#sponsor-mob-border{
    border-bottom:4px solid #F77D48;
}

.sponsor-mob-img{
    display: flex;
    flex-direction:column;
    
}
.row-mob{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.sponsor-mob-img img{
    width:30%;
    padding:20px;
    
}
#row-2-img{
    width: 70%;
}

@media screen and (min-width:700px) {
    .sponsor-mob{
        display: none;
    }
}
@media screen and (max-width:500px) {
    .sponsor-mob{
        padding: 40px 20px;
    }
}
@media screen and (max-width:400px) {
    .sponsor-mob{
        padding: 40px 15px;
    }
}
@media screen and (max-width:350px) {
    .sponsor-mob{
        padding: 40px 10px;
    }
}
@media screen and (max-width:300px) {
    .sponsor-mob{
        padding: 30px 8px;
    }
    .sponsor-mob h1{
        font-size: 20px;
    }
}
@media screen and (max-width:220px) {
    .sponsor-mob{
        padding: 20px 5px;
    }
    .sponsor-mob h1{
        font-size: 16px;
    }
}

.th-contactus-borderbt {
  border-bottom: 4px solid #ffc700;
  display: inline;
}

.th-contact-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
}

/* google map */
.th-gmap-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #000000;
}

.th-get-in-touch-header,
.th-contactus-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #521e86;
}

.th-contact-form-input,
.th-contact-form-textarea {
  color: #494949;
  background: #f4f4f4;
  border: none !important;
  border-radius: 5 !important;
}

.th-contact-form-input {
  height: calc(1.5em + 1rem + 10px) !important;
}

.th-contact-form-input:focus,
.th-contact-form-textarea:focus {
  box-shadow: none !important;
  color: #494949;
  background: #f4f4f4;
}

.th-contactus-submit-button {
  width: 40%;
  background-color: #007af2;
  color: white;
  border: none !important;
  border-radius: 0 !important;
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .th-get-in-touch-header {
    font-size: 60px;
  }
  .th-contactus-header {
    font-size: 50px;
  }
  .th-contactus-submit-button {
    width: 50%;
  }
  .th-contact-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .th-get-in-touch-header,
  .th-contactus-header {
    font-size: 40px;
  }
  .th-contactus-submit-button {
    width: 75%;
  }
  .th-gmap-header {
    font-size: 24px;
  }
  .th-contact-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 578px) {
  .th-get-in-touch-header {
    font-size: 28px;
  }
  .th-contactus-header {
    font-size: 36px;
  }
  .th-contact-text {
    margin-top: 0.5rem !important;
  }
}

.th-contact-form {
  width: 20rem;
}

.th-footer-contact-form {
  margin: auto;
}

@media only screen and (min-width: 968px) and (max-width: 1156px) {
  .th-contact-form {
    width: 15rem;
  }
}

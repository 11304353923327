/* header */

.test{
  text-decoration: none;
  /* background-color: #CB01FF; */
}
.test:hover{
  text-decoration: none;
}

.th-header {
  z-index: 10000;
  top: 0;
  position: fixed;
  width: 100%;
  padding:22px 0;
  transition: all ease 0.5s;
  background: rgba(0, 0, 0, 0.7);
}
.nav-links{
  text-decoration: none;
}
.th-header-bg {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px 0 rgb(50 50 50 / 50%);
  padding:14px 0px;
  transition: all ease 0.5s;
}
.th-head-change{
  width: 100%;
}

.th-header-logo,
.th-nss-logo {
  width: 64px;
}

.th-clg-name{
  z-index: 60000;
}

.th-nss{
  font-family: Verdana;
}

.th-nss,
.th-clg-name {
  color:#FFF;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-decoration: none;
  text-decoration-line: white;
}
.th-nss:hover{
  text-decoration: none;
}

.left-nav{
  width: 40%;
}
.right-nav{
  width: 60%;
  justify-content: space-between;
  text-decoration: none;
}


.th-head-btn{
  background:linear-gradient(to right, rgba(0, 71, 255, 1), rgba(204, 0, 255, 1));
  border-radius: 30px;
  padding: 10px 20px;
  border: none;
}
.th-head-btn:hover{
  background:linear-gradient(to left, rgba(0, 71, 255, 1), rgba(204, 0, 255, 1));
}
.th-hover:hover{
  border-bottom: 3px solid white;
}

@media only screen and (max-width:1330px){
  .left-nav{
    width:35%;
  }
  .right-nav{
    width: 65%;
  } 
}
@media only screen and (max-width:1240px){
  .left-nav{
    width:30%;
  }
  .right-nav{
    width: 70%;
  } 
  .th-nss,
  .th-clg-name {
    font-size: 16px;
  }
}
@media only screen and (max-width:1100px){
  .left-nav{
    width:28%;
  }
  .right-nav{
    width: 72%;
  } 
  .th-nss,
  .th-clg-name {
    font-size: 15px;
  }
  .th-header-logo {
    width: 55px;
  }
}



@media only screen and (min-width: 768px) and (max-width: 1030px) {
  .left-nav{
    width:70%;
  }
  .right-nav{
    width: 30%;
  } 
  .th-header-logo {
    width: 50px;
  }
  .th-nss,
  .th-clg-name {
    font-size: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .left-nav{
    width:80%;
  }
  .right-nav{
    width: 20%;
  } 
  .th-header {
    padding: 18px 0;
  }

  .th-header-bg {
    padding: 13px 0;
  }

  .th-header-logo {
    width: 40px;
  }
  .th-nss,
  .th-clg-name {
    font-size: 12px;
  }
}
/*  header ends*/

/* backdrop */
.th-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000080;
  z-index: 950;
}

/* side drawer */
.th-sidedrawer-web,
.th-sidedrawer-mob {
  top: 85px;
  font-weight: 500;
  height: 100%;
  background:rgba(15, 15, 15, 1);
  color: white;
  position: fixed;
  width: 75%;
  z-index: 970;
  transition: transform 0.6s ease-out;
}

.th-sidedrawer-web {
  top: 77px;
  left: 0;
  max-width: 360px;
  transform: translateX(-100%);
}

.th-sidedrawer-mob {
  top: 65px;
  left: 0;
  max-width: 305px;
  transform: translateX(-100%);
}

.th-sidedrawer-web.open,
.th-sidedrawer-mob.open {
  transform: translateX(0%);
}

.th-sidebar-list-item {
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color:rgba(255, 255, 255, 1);
}
.th-sidebar-list-item:hover {
  background-color: rgba(0, 0, 0, 1);
}
.change-btn-color{
  /* color: #CB01FF; */
  /* color: red; */
  background: linear-gradient(90deg, #0047FF 2.47%, #CB01FF 96.56%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.th-sidebar-arrow,
.th-sidebar-icon {
  color: white;
  opacity: 0.8;
}

.th-sidebar-links {
  bottom: 2.5%;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .th-sidebar-list-item {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 320px) {
  .th-sidebar-list-item {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
/* side bar ends */

.active p {
  border-bottom: 3px solid white;
}

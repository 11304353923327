.slick-next::before {
  content: unset !important;
  color: #fff !important;
}
.slick-prev:before {
  content: unset !important;
  color: #fff !important;
}

.th-theme-color:hover {
  color: rgb(31, 4, 56);
}

.th-arrow-slider-right,
.th-arrow-slider-left {
  line-height: 15px !important;
  background-color: #fff !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  z-index: 800;
}
.th-arrow-slider-right {
  margin-right: -15px !important;
}
.th-arrow-slider-left {
  margin-left: -15px !important;
}
.th-arrow-slider-right img,
.th-arrow-slider-left img {
  height: 25px;
  width: 25px;
}

@media only screen and (max-width: 768px) {
  .th-arrow-slider-right img,
  .th-arrow-slider-left img {
    height: 14px !important;
    width: 14px !important;
  }
  .th-arrow-slider-right,
  .th-arrow-slider-left {
    line-height: 10px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .th-arrow-slider-left {
    margin-left: 16px !important;
  }
  .th-arrow-slider-right {
    margin-right: 16px !important;
  }
}

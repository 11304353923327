
.Footer-wrapper{
    height: 100%;
 /* width: 100%; */
 background: #000000;
 overflow-x: hidden;
}
.Footer-container{
    color: #FFFFFF;
   display: flex;
}
.footer-left {
    z-index: 2;
 padding-top: 5%;
 padding-left: 18%;
}
.map-container {
margin: 10vh;
margin-left: 13vw;
z-index: 1000;
}
.map-img{
    position: relative;
    z-index: 100000;
}
.designimgr{
    position: absolute;
    right: 0px;
}
.designimgl{
    position: absolute;
    left: 0;
    margin-top: 28vh;
}
.footertxt-container h1{
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;  
}
.address p{
    font-family: 'Verdana';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
color: #BDBDBD;
}
.contact{
    margin-left: 50px;
margin-top: 20px;
}
.contact p{
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #BDBDBD;
}
.address-contact {
    z-index: 1;
  display:flex;
}
.address {
    Width:219px;
    Height:112px;
}
.sm-icons {
    display: flex;
  margin-top: 8vh;
  justify-content: space-around;
  width: 22vw;
}

.line-footer {
    border: 0.5px solid #CCCCCC;
    transform: rotate(0deg);
    background:  rgba(204, 204, 204, 1);
   width: 53vw; 

   margin-left: 23.5vw;
}
.copyright-footer {
    text-align: center;
    font-family: 'Verdana';
    font-style: normal;
    /* width: 100%; */
    margin-bottom: 3vh;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    margin-top: 10px;
    color: #CCCCCC;
}
@media (max-width: 900px) {
.Footer-wrapper{
    width: 100%;
}
    .Footer-container{
        width: 100vw;
        display: flex;
        flex-direction: column-reverse;
    }
    .map-container{
    text-align: center;
    align-items: center;
    justify-content: center;
    }
   .map-img{
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 30vh;
    width: 75vw;
   }
   .sm-icons{
    width: 70vw;
   }
   .designimgl{
    display: none;
   }
   .line-footer{
     margin-top: 6vh;
   }
}
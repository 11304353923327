
.sponsor-web{
    width: 100%;
    padding: 47px 99px;
}

.sponsor-web h1{
    text-align: center;
    padding-bottom:20px;
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
#sponsor-web-border{
    border-bottom:4px solid #F77D48;
}

.sponsor-web-img{
    display: flex;
    flex-direction:column;
    
}
.row-web{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.sponsor-web-img img{
    width:220px;
    padding: 25px;
    
}

@media screen and (max-width:1100px) {
    .sponsor-web-img img{
        width:210px;
        padding: 20px;   
    }
    .sponsor-web{
        padding: 47px 90px;
    }
}
@media screen and (max-width:1000px) {
    .sponsor-web-img img{
        width:200px;
        padding: 20px; 
    }
    .sponsor-web{
        padding: 47px 80px;
    }
}
@media screen and (max-width:900px) {
    .sponsor-web-img img{
        width:190px;
        padding: 20px; 
    }
    .sponsor-web{
        padding: 47px 70px;
    }
}
@media screen and (max-width:800px) {
    .sponsor-web-img img{
        width:165px;
        padding: 20px; 
    }
    .sponsor-web{
        padding: 47px 70px;
    }
}
@media screen and (max-width:700px) {
    .sponsor-web{
        display: none;
    }
}
.teams-intro {
  min-height: 450px;
  background: #fdfdfd;
}

.th-team-intro {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  color: #000000;
  width: 65%;
  margin-bottom: 150px;
}

.position-heading {
  text-align: center;
  color: #521e86;
  font-weight: 600;
  margin-bottom: 3%;
  font-size: 30px;
}

.position-heading-top {
  font-size: 50px;
  color: #521e86;
  font-weight: 600;
}

.th-team-hr-line {
  border-top: 4px solid #ffc700 !important;
  width: 9rem;
  margin-top: -5px;
}

.teams-avatar-card {
  width: 320px;
  height: 400px;
  position: relative;
  transform: translateX(-50%);
  left: 45%;
  margin-right: 4%;
  margin-left: 4%;
  margin-bottom: 4%;
  background-size: cover;
  box-shadow: 0 0 6px 6px rgba(222, 188, 238, 0.562);
  border-radius: 10px;
}

.teams-contact-icons {
  padding: 15px 30px;
  position: absolute;
  bottom: 10px;
}

.teams-avatar-mail {
  position: relative;
  left: 220px;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  .teams-avatar-card {
    margin-bottom: 10%;
    margin-right: 4%;
    margin-left: 0;
  }
  .th-team-intro {
    width: 100%;
  }
}

.teams-avatar-img {
  position: relative;
  top: 30px;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.teams-avatar-name {
  text-align: center;
  font-family: "Poppins";
  color: #222222;
  font-weight: 600;
  font-size: 22px;
  position: relative;
  top: 30px;
}

.teams-avatar-vertical {
  cursor: pointer;
  position: relative;
  top: 40px;
  color: #302f2fce;
  font-size: 15px;
  font-weight: 200;
  font-family: "Poppins";
}

.dropdown {
  margin-bottom: 3%;
  padding-right: 25px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  outline: none;
}

/* react select */
.css-yk16xz-control {
  transition: all 0.3s ease !important;
  background: #fafafa !important;
  border-radius: 5px !important;
  border: 1px solid #521e86 !important;
}

.css-1pahdxg-control {
  outline: none !important;
  border-color: none !important;
  box-shadow: none !important;
  border: 1px solid #521e86 !important;
}

@media only screen and (min-width: 768px) {
  .teams-avatar-card {
    width: 280px;
    height: 350px;
  }
  .teams-avatar-mail {
    left: 180px;
  }
}

/* home top */
.th-home-top-container {
  position: relative;
}

.th-home-top-img {
  width: 100%;
  opacity: 1;
  display: block;
}
.th-home-container {
  margin-left: 8rem;
}
.th-home-top-middle {
  opacity: 1;
  position: absolute;
  bottom: 5%;
  margin-left: 2rem;
  /* margin-bottom: 5rem; */
  margin-bottom: 30px;
}
.th-border{
  border-bottom: 5px solid white;
  border-radius: 2px;
}

.th-home-nss-header,
.th-home-nss-tagline {
  color: #fff;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
}
.th-home-nss-header {
  font-size: 72px;
}
.th-home-nss-tagline {
  font-size: 30px;
  padding-top: 10px;
  padding-left: 100px;
}
.th-home-testimonials {
  padding-top: 70px;
  padding-bottom: 100px;
  background: linear-gradient(180deg, #fdfdfd 0%, rgba(246, 246, 246, 0) 100%);
}
/* What we do */
.th-home-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  color: #521e86;
}

.th-home-intro {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  color: #000000;
}
.th-home-hr-line {
  border-top: 2px solid #ffc700 !important;
}
.th-home-our-journey {
  display: flex;
  padding-top: 70px;
  padding-bottom: 50px;
  width: 100%;
  justify-content: space-evenly;
  background: rgba(249, 249, 249, 0);
}

.th-home-our-journey-left {
  margin-top: 20px;
  width: 25%;
}
.th-home-our-journey-right {
  margin-top: 100px;
  width: 50%;
}
.th-home-latest-blogs {
  padding-top: 70px;
  padding-bottom: 50px;
  background: rgba(246, 246, 246, 0);
}
.th-home-about-us {
  display: flex;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 50px;
  justify-content: space-evenly;
  background: rgba(250, 239, 233, 0.4);
}

.th-home-about-us-left {
  width: 35%;
}
.th-home-about-us-right {
  margin-top: 150px;
  width: 40%;
}

.th-home-about-us-desc {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  color: #000000;
}
.th-home-about-us-card {
  padding: 20px;
  /* width: 502px; */
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
}
.th-home-about-us-card-desc {
  margin-top: 10px;
  font-size: 20px;
}
/* background: linear-gradient(180deg, #FDFDFD 0%, rgba(246, 246, 246, 0) 100%); */
@media only screen and (max-width: 992px) {
  .th-hor-row {
    display: none !important;
  }

  .th-home-nss-header {
    font-size: 30px;
  }

  .th-home-nss-tagline {
    font-size: 16px;
    margin-left: 0 !important;
  }

  .th-home-title {
    font-size: 48px;
  }

  .th-home-hori-row {
    width: 110px;
  }
}
.th-collaboration-dictionary {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  grid-row-gap: 10px;
}

.th-collaboration-dictionary > .term {
  max-width: 20rem;
  margin: 0.5rem;
  margin-bottom: 3rem;
  padding: 2rem 2rem;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 100ms ease-in-out;
}

@media only screen and (max-width: 768px) {
  .th-home-intro {
    font-size: 16px;
    line-height: 24px;
  }
  .th-home-title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 578px) {
  .th-home-top-middle {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .th-home-nss-header {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .th-home-nss-tagline {
    font-size: 14px;
    margin-left: 0 !important;
  }
  .th-home-about-us {
    flex-direction: column-reverse;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .th-home-about-us-left {
    width: 90%;
    align-self: center;
  }
  .th-home-about-us-right {
    width: 90%;
    margin-top: 0px;
    align-self: center;
  }
  .th-home-our-journey {
    padding-bottom: 10px;
    padding-top: 10px;
    align-self: center;
    flex-direction: column;
  }
  .th-home-our-journey-left {
    width: 90%;
    align-self: center;
  }
  .th-home-our-journey-right {
    width: 100%;
  }
  .th-home-intro {
    width: 100%;
  }
}

/* carousel section */
.th-home-carousel,
.th-home-carousel:hover {
  text-decoration: none !important;
  color: #000 !important;
}

.th-home-carousel-img-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  border: none !important;
}

.th-home-carousel-img {
  width: 100%;
  cursor: pointer;
  height: 100%;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.th-home-carousel-container {
  position: relative;
}

.th-home-carousel-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.th-home-carousel-img-wraper:hover .th-home-carousel-img {
  opacity: 0.5;
}

.th-home-carousel-img-wraper:hover .th-home-carousel-middle {
  opacity: 1;
}

.th-home-carousel-btn {
  background-color: #0065f2;
  color: #fff;
  border-radius: 8px !important;
  width: 120px;
}

.th-home-carousel-btn:hover {
  color: #fff;
}

.image1 {
  position: relative;
  left: 25%;
  height: 200px;
  margin-top: -80px;
}

.image2 {
  position: relative;
  left: 30%;
  height: 200px;
  margin-top: -80px;
}

.image3 {
  position: absolute;
  left: 0px;
  top: 180px;
}

.content {
  position: relative;
  width: 350px;
  height: 180px;
  margin-left: auto;
  top: -200px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  text-align: left;
  color: #222222;
}

.box1 {
  position: absolute;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 95px;
  text-align: center;
  color: #521e86;
  left: 70px;
}

.box2 {
  position: absolute;
  top: 215px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 0px;
  text-align: center;
  color: #888888;
  left: 70px;
}

@media only screen and (min-width: 992px) {
  .th-home-carousel-img-wraper {
    height: 16vw;
    width: 20vw;
  }
  .th-home-carousel-img-box {
    width: calc(100% - 2rem);
    height: calc(100% - 1.5rem);
  }
  .th-la-social-icon-box {
    position: absolute;
    bottom: 2.5%;
  }
  .th-home-about-us-card {
    width: 400px;
  }
  .th-home-our-journey-left {
    width: 30%;
  }
  .th-home-our-journey-right {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .th-home-carousel-img-wraper {
    height: 24vw;
    width: 16vw;
  }
  .th-home-carousel-img-box {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
  }
  .th-home-about-us-card {
    width: 250px;
  }
  .th-home-our-journey-left {
    width: 30%;
  }
  .th-home-latest-blogs {
    width: 95%;
  }
  .th-home-testimonials {
    width: 95%;
  }
}

@media only screen and (min-width: 578px) and (max-width: 768px) {
  .th-home-carousel-img-wraper {
    height: 32vw;
    width: 12vw;
  }
  .th-home-carousel-img-box {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
  }
}

@media only screen and (max-width: 578px) {
  .th-home-carousel-img-wraper {
    height: 48vw;
    width: 12vw;
  }
  .th-home-carousel-img-box {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
  }
  .th-home-about-us-card {
    width: 200px;
  }
  .th-home-about-us {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column-reverse;
    align-self: center;
  }
  .th-home-about-us-right {
    width: 90%;
    align-self: center;
    margin-top: 10px;
  }
  .th-home-about-us-left {
    width: 70%;
    align-self: center;
    margin-top: 10%;
  }
  .th-home-our-journey {
    flex-direction: column;
  }
  .th-home-our-journey-right {
    width: 90%;
    align-self: center;
  }
  .th-home-our-journey-left {
    align-self: center;
    width: 90%;
  }
  .th-home-latest-blogs {
    margin-left: 5%;
  }
  .th-home-testimonials {
    margin-left: 5%;
  }
}

.ant-modal-close-x {
  display: none !important;
}

.th-home-about-collaboration {
  width: 100%;
  background: linear-gradient(180deg, #fdfdfd 0%, rgba(246, 246, 246, 0) 100%);
  padding-top: 70px;
  padding-bottom: 50px;
}

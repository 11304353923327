.th-loader-modal-content {
  background: transparent !important;
  border: none !important;
}

/* spinner */
.th-spinner-bg {
  height: 360px;
}

.spinner-border-sm {
  height: 3.5rem !important;
  width: 3.5rem !important;
  color: #521e86;
}

/* ripple loader */
.ripple-loader {
  position: relative;
  width: 64px;
  height: 64px;
}

.ripple-loader div {
  position: absolute;
  border: 4px solid #521e86;
  border-radius: 50%;
  animation: ripple-loader 1s ease-out infinite;
}

.ripple-loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple-loader {
  0% {
    top: 32px;
    left: 32px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    opacity: 0;
  }
}

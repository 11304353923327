@media only screen and (min-width: 1025px) {
  .container {
    max-width: 85vw !important;
  }
}

@media only screen and (min-width: 580px) and (max-width: 1024px) {
  .container {
    max-width: 85vw !important;
  }
}

.th-poppins {
  font-family: "Poppins", sans-serif;
}
.th-inter {
  font-family: "Inter", sans-serif;
}
.th-10 {
  font-size: 10px;
}
.th-12 {
  font-size: 12px;
}
.th-13 {
  font-size: 13px;
}
.th-14 {
  font-size: 14px;
}
.th-15 {
  font-size: 15px;
}
.th-16 {
  font-size: 16px;
}
.th-17 {
  font-size: 17px;
}
.th-18 {
  font-size: 18px;
}
.th-20 {
  font-size: 20px;
}
.th-22 {
  font-size: 22px;
}
.th-24 {
  font-size: 24px;
}
.th-26 {
  font-size: 26px;
}
.th-30 {
  font-size: 30px;
}
.th-36 {
  font-size: 36px;
}
.th-40 {
  font-size: 40px;
}
.th-42 {
  font-size: 42px;
}
.th-48 {
  font-size: 48px;
}
.th-72 {
  font-size: 72px;
}
/* font weight */
.th-thin {
  font-weight: 100;
}
.th-light {
  font-weight: 300;
}
.th-regular {
  font-weight: 400;
}
.th-medium {
  font-weight: 500;
}
.th-bold {
  font-weight: 600;
}
.th-bold-700 {
  font-weight: 700;
}
/* font color */
.th-black {
  color: #4b4b4b;
}
.th-grey {
  color: #959595;
}
.th-white {
  color: #ffffff;
}
.th-blue {
  color: #007af2;
}
.th-theme-color {
  display: none;
  color:white;
  border:2px solid gray;
  height: 40px;
  width: 40px;
  padding: 7px;
  border-radius:8px;
}
/* BG Color */
.th-bg-white {
  background: #ffffff;
}
/* BG Color Neutral */
.th-bg-primary {
  background: #007af2;
}

.th-bg-black {
  background: #000000;
}
.th-bg-grey-4 {
  background: #4b4b4b;
}
.th-bg-grey-3 {
  background: #959595;
}
.th-bg-grey-2 {
  background: #d5d5d5;
}
.th-bg-grey-1 {
  background: #f5f5f5;
}
.th-bg-white {
  background: #ffffff;
}
.th-bg-transparent {
  background: transparent;
}

@media screen and (max-width: 767px) {
  .th-hidden-sm {
    display: none !important;
  }
}

.th-nss {
  display: block;
}

@media screen and (max-width: 1024px) {
  .th-hidden-sm {
    display: none !important;
  }
  .th-theme-color {
    display: block;
  }
  .th-nss {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}

/* List */
.th-list-none {
  list-style-type: none;
  padding: 0 !important;
}

/* Margin */
.th-top-margin {
  margin-top: 8.5rem;
}
.th-top-margin-2 {
  margin-top: 7.5rem;
}

@media screen and (max-width: 768px) {
  .th-top-margin {
    margin-top: 5rem;
  }
  .th-top-margin-2 {
    margin-top: 4.5rem;
  }
}

.th-link,
.th-link:hover {
  text-decoration: none;
}

/* Loader section */
.th-loader-fade,
.th-spinner-fade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4000;
}

.th-loader-fade {
  background-color: #f3f3f3;
  opacity: 0.6;
}

.th-spinner-fade {
  background-color: #cdcdcd;
}

.th-default-spinner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
}

.styles_scroll-to-top__2A70v svg {
  fill: #521e86;
}

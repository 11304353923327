.mainbox {
  width: 100vw;
  height: 250vh;
}

.main1 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 4;
  background-color: transparent;
}

.background {
  width: 100vw;
  height: 250vh;
  position: absolute;
  z-index: 0;
}

.vectora {
  position: absolute;
  z-index: 1;
  background-color: transparent;
}

.vectorb {
  padding-top: 10vh;
  display: flex;
  justify-content: right;
  background-color: transparent;
  z-index: 2;
}

.row {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
  background-color: transparent;
  column-gap: 3vh;
}

.col-12 {
  width: 100vw;
  height: 7vh;
  color: #000;
  font-family: Verdana;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
}

.row1 {
  width: 100vw;
  height: 14vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.aboutc1 {
  width: 30vw;
  height: 14vh;
  color: #525252;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Verdana;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
  background-color: transparent;

}

.row2 {
  width: 80vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.aboutc2 {
  position: relative;
  width: 60vw;
  height: 50vh;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.img5 {
  width: 80%;
  height: 100%;
}

.layer1 {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
}

.photo {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.photo-small-container1 {
  width: 12vw;
  height: 30vh;
  position: absolute;
  z-index: 6;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 29vh;

}

.blue-line {

  width: 5vw;
  height: 3vh;
  position: absolute;
  z-index: 6;
  background-color: #0067AF;
  flex-shrink: 0;
  display: flex;
  align-items: normal;

}


.photo-container1 {
  transform: rotate(10.519deg);
  width: 12vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: transparent;

  .photo-small-container21 {
    width: 12vw;
    height: 30vh;

    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 3.5vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: flex;
    align-items: first baseline;
    justify-content: center;

    .img1 {
      width: 11vw;
      height: 26vh;
      z-index: 5;
      background-color: transparent;
      background-size: 100% 100%;
      background-repeat: no-repeat;

    }

  }

}

.photo-container2 {
  transform: rotate(-6.687deg);
  width: 12vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .photo-small-container22 {
    width: 12vw;
    height: 30vh;

    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 3.5vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: flex;
    align-items: first baseline;
    justify-content: center;

    .img2 {
      width: 11vw;
      height: 26vh;
      background-color: transparent;

      background-size: 100% 100%;
      background-repeat: no-repeat;

    }
  }

}

.photo-container3 {
  transform: rotate(6.479deg);
  width: 12vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .photo-small-container23 {
    width: 12vw;
    height: 30vh;

    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 3.5vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: flex;
    align-items: first baseline;
    justify-content: center;



    .img3 {
      width: 11vw;
      height: 26vh;
      background-color: transparent;

      background-size: 100% 100%;
      background-repeat: no-repeat;

    }
  }
}

.photo-container4 {
  transform: rotate(-9.94deg);
  width: 12vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .photo-small-container24 {
    width: 12vw;
    height: 30vh;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 3.5vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: flex;
    align-items: first baseline;
    justify-content: center;

    .img4 {
      width: 11vw;
      height: 26vh;
      background-color: transparent;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.main2 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  padding-top: 150vh;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.vectorc {
  padding-top: 110vh;
  position: absolute;
  z-index: 3;
  background-color: transparent;
  display: flex;
  justify-content: left;
}

.maincont1 {
  width: 100vw;
  height: 50vh;
  z-index: 3;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  gap: 7vw;
}

.man {
  height: 45vh;
  width: 40vw;
  background-color: transparent;
  z-index: 3;
}

.img9 {
  background-color: transparent;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.whatus {
  width: 40vw;
  height: 35vh;
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 3;
  background-color: transparent;
  overflow:auto;
}


.head {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1%;
  z-index: 3;

}

.maincont2 {
  width: 100vw;
  height: 40vh;
  padding-top: 10vh;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  z-index: 3;

}

.cont {
  width: 40vw;
  height: 38vh;
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-direction: column;
  background-color: transparent;
  backdrop-filter: blur(1px);
  border: transparent solid 1px;
  border-radius: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2%;
  text-align: center;
  z-index: 3;
  overflow:auto;
}

.head1 {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1%;
  background-color: transparent;
  z-index: 3;
  
}


@media only screen and (max-width:1200px ) {

  .aboutc1 {
    width: 50vw;
  }

  .row2 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aboutc2 {
    width: 80vw;
  }

  .cont {
    width: 60vw;
    backdrop-filter: blur(0px);

  }

  .vectorb {
  
    visibility: hidden;
  }

  .mainbox {
    padding: auto;
    margin: auto;
  }

  .row2 {
    width: 100vw;
    height: 50vh;
  }

  .aboutc2 {
    width: 100vw;
    height: 50vh;
  }

  .vectora {
    display: none;
  }



  .vectorc {
    display: none;

  }

  .mainbox {
    width: 100vw;
    height: 250vh;
  }

  .main2 {

    width: 100vw;
    height: 150vh;
padding-top: 150vh;
  }

  .whatus {
    width: 50vw;
    height: 40vh;
  }

  .img9 {
    width: 40vw;
    height: 40vh;
  }
}

@media only screen and (max-width:400px) {
  .background{
    height: 210vh;
  }
  .mainbox {
    height: 210vh;
  }

  .main1 {
    height: 65vh;
  }

  .row {
    padding-top: 10vh;
    height: 65vh;
  }

  .col-12 {
    height: 5vh;
    font-size: 23.139px;
  }

  .row1 {
    height: 8vh;
  }

  .aboutc1 {
    width: 80vw;
    height: 8vh;
    text-align: center;
    padding: 2%;
    font-size: 10px;
  }

  .row2 {
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutc2 {
    width: 100vw;
    height: 30vh;
  }

  .photo-container1 {
    width: 15vw;
    height: 20vh;

    .photo-small-container21 {
      width: 15vw;
      height: 20vh;

      .img1 {
        width: 14vw;
        height: 16vh;

      }

    }

  }

  .photo-container2 {
    width: 15vw;
    height: 20vh;


    .photo-small-container22 {
      width: 15vw;
      height: 20vh;

      .img2 {
        width: 14vw;
        height: 16vh;

      }
    }

  }

  .photo-container3 {
    width: 15vw;
    height: 20vh;


    .photo-small-container23 {
      width: 15vw;
      height: 20vh;

      .img3 {
        width: 14vw;
        height: 16vh;

      }
    }
  }

  .photo-container4 {
    width: 15vw;
    height: 20vh;


    .photo-small-container24 {
      width: 15vw;
      height: 20vh;


      .img4 {
        width: 14vw;
        height: 16vh;

      }
    }
  }

  .photo-small-container1 {
    width: 15vw;
    height: 20vh;
    padding-bottom: 19vh;
  }

  .blue-line {
    width: 5vw;
    height: 2vh;
  }

  .main2 {
    width: 100vw;
    height: 100vh;
    padding-top: 110vh;
  }

  .head {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .whatus {
    width: 40vw;
    height: 25vh;
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    backdrop-filter: none;
  }

  .man {
    width: 30vw;
    height: 20vh;
  }

  .cont {
    font-size: 10px;
    backdrop-filter: none;
    width: 70vw;
    height: 30vh;
  }

  .head1 {
    font-size: 15px;
  }
}
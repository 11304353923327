.special-heading {
  font-size: 45px;
}

@media only screen and (min-width: 1025px) {
  .th-event-container {
    max-width: 70vw !important;
  }
}

@media only screen and (min-width: 580px) and (max-width: 1024px) {
  .th-event-container {
    max-width: 75vw !important;
  }
}

@media only screen and (max-width: 992px) {
  .th-event-card-img {
    height: 180px !important;
  }
}

.th-events-intro {
  font-size: 40px;
}

.th-events-intro-container {
  min-height: auto;
  margin-bottom: 10%;
}

.th-event-card-img,
.th-events-page-img,
.th-event-thumbimg {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.th-event-card-img {
  width: 100%;
  height: 100%;
}
.th-events-page-img {
  height: 320px;
}

.th-events-tab-bar {
  width: 50%;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  background-color: #f3f3f3;
  color: gray;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}

.focus {
  color: white;
  background-color: #0065f2;
  box-shadow: 3px 7px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.th-event-card {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none !important;
  cursor: pointer;
}

.th-events-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #521e86;
}

.th-events-card-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  color: #521e86;
}

/* perticular events and init page */
.th-event-page-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #521e86;
}

.th-event-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.5px;
  line-height: 30px;
  text-align: justify;
  color: #000000;
}

.th-event-page-gallery-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  color: #521e86;
}

@media only screen and (max-width: 992px) {
  .th-events-tab-bar {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 2.5rem;
    font-weight: normal;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 768px) {
  .th-event-page-title,
  .th-event-page-gallery-title {
    font-size: 24px;
  }
  .th-event-description {
    font-size: 14.5px;
    line-height: 24.5px;
  }
  .th-events-card-title {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .th-events-card-text {
    font-size: 14.5px;
    text-align: justify;
  }
}
